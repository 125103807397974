<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{api_key: currentUser.api_key, description : JSON.stringify(description)}">

        <div class="col-12">
            <validated-input label="Title" v-model="model.title"
                             :rules="{required:true}" :disabled="loading"/>
        </div>

        <div class="col-12">
            <validated-textarea label="Title Description" v-model="model.title_description"
                                :rules="{required:true, min:400, max:430}" :disabled="loading"/>
        </div>
        <div class="col-12 fl-eqh-r mb-1">
            <div class="btn-group">
                <btn @click="addItem('h')" type="button" size="xs" text="Heading" :disabled="loading"/>
                <btn @click="addItem('d')" type="button" size="xs" text="Description" :disabled="loading"/>
                <btn @click="addItem('b')" type="button" size="xs" text="Bullets" :disabled="loading"/>
                <btn @click="clearAll" type="button" size="xs" text="Clear All" :disabled="loading"/>
            </div>
        </div>
        <div class="col-12">
            <div v-for="(i, index) in description" :key="index">
                <div v-if="i.type==='h'" class="fl-te-c mb-1 mt-3">
                    <label>Heading</label>
                    <btn color="danger" @click="deleteItem(index)" type="button" size="xs"
                         icon="fa fa-trash" :disabled="loading"/>
                </div>
                <validated-input v-if="i.type==='h'" name="Heading" v-model="i.value"
                                 :rules="{required:false}" :disabled="loading" class="mb-1"/>
                <div v-if="i.type==='d'" class="fl-te-c mb-1 mt-3">
                    <label>Description</label>
                    <btn color="danger" @click="deleteItem(index)" type="button" size="xs"
                         icon="fa fa-trash" :disabled="loading"/>
                </div>
                <validated-textarea v-if="i.type==='d'" v-model="i.value"
                                    :rules="{required:false}" :disabled="loading" class="mb-1"/>
                <div v-if="i.type==='b'" class="b-1 round-2 p-1 mt-3">
                    <div class="fl-eqh-r m-1">
                        <div class="btn-group">
                            <btn color="danger" @click="deleteItem(index)" type="button" size="xs"
                                 icon="fa fa-trash" text="Delete All" :disabled="loading"/>
                            <btn @click="addPoint(index)" type="button" size="xs" text="Add Point" :disabled="loading"/>
                        </div>
                    </div>
                    <div class="m-1" v-for="(j, ind) in i.value" :key="ind">
                        <div class="fl-te-c mb-1 mt-3">
                            <label>Point</label>
                            <btn color="danger" @click="deletePoint(index, ind)" type="button" size="xs"
                                 icon="fa fa-trash" :disabled="loading"/>
                        </div>
                        <validated-input name="Point" v-model="j.value"
                                         :rules="{required:false}" :disabled="loading"/>
                    </div>

                </div>
            </div>
        </div>
        <div class="row m-1">
            <checkbox-input class="col-6" label="Disable" v-model="model.disabled"
                            :rules="{required:false}" :disabled="loading"/>

        </div>
        <div class="col-12 mt-2">
            <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                 loading-text="Saving..." :disabled="loading"/>
        </div>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddCareer',
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl      : urls.admin.career.addEdit,
            description : []
        };
    },

    methods : {
        addItem (type) {
            if (type === 'b') {
                this.description.push({ id : 'Item', type : type, value : [{ id : 'Point', value : '' }] });
            } else {
                this.description.push({ id : 'Item', type : type, value : '' });
            }
        },
        addPoint (index) {
            this.description[index].value.push({ id : 'Item', value : '' });
        },
        deleteItem (index) {
            this.description.splice(index, 1);
        },
        deletePoint (index, pointIndex) {
            this.description[index].value.splice(pointIndex, 1);
        },
        clearAll () {
            this.description = [];
        },
        formSuccess () {
            this.$notify('Successfully Added Career..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },
        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
