<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'CAREER'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.careerAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #disabled="{rowData}">
                <checkbox-input v-model="rowData.disabled" @input="disable(rowData)"></checkbox-input>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Career" ref="careerAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddCareer @success="formSuccess"></AddCareer>
        </modal>

        <modal title="Edit Career" ref="careerEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditCareer :initial-data="editingItem" @success="formSuccess"></EditCareer>
        </modal>

        <delete-modal ref="careerDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Career <b v-html="deletingItem && deletingItem.title"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';
import AddCareer from './AddCareer';
import EditCareer from './EditCareer';
import { mapGetters } from 'vuex';

export default {
    name       : 'Career',
    components : { AddCareer, EditCareer },
    computed   : { ...mapGetters(['currentUser']) },
    data () {
        return {
            listUrl      : urls.admin.career.list,
            deleteUrl    : urls.admin.career.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'title',
                    sortField  : 'title',
                    title      : 'Title',
                    titleClass : ''
                },
                {
                    name       : 'title_description',
                    sortField  : 'title_description',
                    title      : 'Title Description',
                    titleClass : ''
                },
                {
                    name       : '__slot:disabled',
                    sortField  : 'disabled',
                    title      : 'Disabled',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.careerAddModal.close();
            refs.careerEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            delete this.editingItem.description;
            this.editingItem.initial_description = item.description;
            this.$refs.careerEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.careerDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Career..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.careerDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        disable (item) {
            const that = this;
            item.api_key = this.currentUser.api_key;
            axios.form(urls.admin.career.addEdit, item).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('Successfully Updated Career..!', 'Success',
                        {
                            type : 'success'
                        });
                } else {
                    that.$notify(json.errors, 'Error',
                        {
                            type : 'warning'
                        });
                    item.disabled = !item.disabled;
                }
            }).catch(function (err) {
                item.disabled = !item.disabled;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
